import React from "react";
import { Hours } from "../../components";

function HoursPage() {
  return (
    <div className="Hours">
      <Hours />
    </div>
  );
}

export default HoursPage;
