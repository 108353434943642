import React from "react";
import Hero from "../../components/hero/hero";

function About() {
  return (
    <div className="About">
      <Hero />
    </div>
  );
}

export default About;
