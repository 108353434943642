let bulletsize = "1.2rem";
export const serviceData = [
  {
    id: 4,
    imgURL: "/images/traffic.webp",
    name: "POA & Traffic Offenses",
    description: (
      <p>
        Need help with traffic tickets? I've got you covered. From speeding
        tickets to careless driving, I provide expert assistance and
        representation to tackle your traffic ticket matters. Let me handle the
        legal complexities while you regain peace of mind on the road.
      </p>
    )
  },
  {
    id: 3,
    imgURL: "/images/landlord-tenant.webp",
    name: "Landlord and Tenant Disputes",
    description: (
      <p>
        Dealing with Ontario Landlord and Tenant Board matters can be complex
        and overwhelming. Rest assured, I am here to offer my expertise and
        assistance. With a deep understanding of the intricacies involved, I can
        provide you with valuable support for your Landlord and Tenant Board
        matters. From disputes and negotiations to representation and legal
        advice, I am dedicated to helping you navigate the process and resolve
        your matters effectively and efficiently.
      </p>
    )
  },

  {
    id: 2,
    imgURL: "/images/small-courts.webp",
    name: "Small Claims Court",
    description: (
      <p>
        Need assistance with Small Claims Court matters? Look no further. I
        specialize in providing comprehensive support for various aspects of
        Small Claims Court cases. Whether you're filing a Plaintiff's Claim or
        defending with a Defendant's Claim, I am here to guide you every step of
        the way. Count on my expertise to help you navigate the complexities of
        the process and achieve the best possible outcome for your case.
      </p>
    )
  },
  {
    id: 5,
    imgURL: "/images/criminal.webp",
    name: "Criminal Defense",
    description: (
      <div>
        <p>
          Facing criminal charges? You don't have to face it alone.
          <br />
          <br />
          At Concept Legal Services, we are dedicated to providing you with
          unwavering legal support and a robust defense strategy, ensuring that
          you are well-informed and protected throughout the criminal process.
          <br />
          <br />
          By pleading guilty to a criminal offense, you acknowledge the
          allegations and relieve the prosecution of the burden to prove your
          case beyond a reasonable doubt. It is crucial to be aware that a
          criminal record can present challenges in terms of employment
          opportunities and international travel.
          <br />
          <br />
          If you are facing criminal charges, our team is here to assist you. We
          understand the importance of assessing your options and taking prompt
          action when charged with a criminal offense. The decisions you make
          during this critical time will have a significant impact on your
          future, and in some cases, your personal freedom. We recognize the
          gravity of a criminal charge and emphasize the importance of
          approaching it with the utmost seriousness.
        </p>
        <h2>Criminal Practice Areas Include:</h2>
        <ul
          style={{
            textAlign: "left",
            fontFamily: "sans-serif",
            fontSize: bulletsize,
            fontWeight: 200
          }}
        >
          <li
            style={{
              textAlign: "left",
              fontFamily: "sans-serif",
              fontSize: bulletsize,
              fontWeight: 200
            }}
          >
            Theft Under $5000
          </li>
          <li
            style={{
              textAlign: "left",
              fontFamily: "sans-serif",
              fontSize: bulletsize,
              fontWeight: 200
            }}
          >
            Fraud Under $5000
          </li>
          <li
            style={{
              textAlign: "left",
              fontFamily: "sans-serif",
              fontSize: bulletsize,
              fontWeight: 200
            }}
          >
            Assault
          </li>
          <li
            style={{
              textAlign: "left",
              fontFamily: "sans-serif",
              fontSize: bulletsize,
              fontWeight: 200
            }}
          >
            Possession of Controlled Substances
          </li>
          <li
            style={{
              textAlign: "left",
              fontFamily: "sans-serif",
              fontSize: bulletsize,
              fontWeight: 200
            }}
          >
            Uttering Threats
          </li>
          <li
            style={{
              textAlign: "left",
              fontFamily: "sans-serif",
              fontSize: bulletsize,
              fontWeight: 200
            }}
          >
            Shoplifting
          </li>
          <li
            style={{
              textAlign: "left",
              fontFamily: "sans-serif",
              fontSize: bulletsize,
              fontWeight: 200
            }}
          >
            Mischief
          </li>
        </ul>
      </div>
    )
  },
  {
    id: 1,
    imgURL: "/images/notary.webp",
    name: "Notary Services",
    description: (
      <p>
        As an authorized Notary Public serving Ontario, I offer professional
        document notarization services. From crucial legal documents like Power
        of Attorney and Affidavits to property-related paperwork like Deeds, I
        ensure the utmost precision and authenticity. Entrust your important
        documents to me for efficient and reliable notarization.
      </p>
    )
  }
];
