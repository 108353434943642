export const headerData = {
  logoURL: "/images/logo.png",
  navLinks: [
    {
      text: "HOME",
      href: "/"
    },
    {
      text: "ABOUT",
      href: "/about"
    },
    {
      text: "HOURS",
      href: "/hours"
    },
    {
      text: "GET A QUOTE",
      href: "mailto:info@conceptlegalservices.com?subject=Quote Request"
    }
  ],
  altText: "Concept Legal Services Logo"
};
